import {Params} from '@angular/router';

import {LocationAutocompleteFormI} from '@controls/location-autocomplete';
import {buildURLParams} from '@helpers/build-url-params';
import {DateTimeUtcConverter} from '@helpers/date-time-utc-converter';
import {fileExtension} from '@helpers/file-extension';
import {mediaTypeByExtension} from '@helpers/media-type-by-extension';

import {CharterNavItem} from './common';

import {BoatPayload, BoatPayloadDto} from '../boat';
import {TableCategoryRowItem} from '../dashboard';
import {
  CharterAdditionalPaymentMethodDto,
  CharterContractStatusDto,
  CharterKindDto,
  CurrencyDto,
} from '../directories';
import {ConditionalStatus, LabeledItem} from '../general';
import {ExtendedLocationItemDto, ExtendedLocationSearchType} from '../location';
import {UploadedFile, UploadedFileType} from '../uploaded-file';

export enum AddEditCharterMode {
  Add = 'add',
  Edit = 'edit'
}

export type AddEditCharterPages =
  'charter-details'
  | 'payment-details'
  | 'guests'
  | 'charter-attachments'
  | 'additional-details';

export type AddEditTripPages =
  'trip-details'
  | 'payment-details'
  | 'guests'
  | 'trip-attachments'
  | 'additional-details';

export enum BrokerFeeType {
  Broker = 1,
  CentralAgency = 2,
}

export enum SystemCharterType {
  Trip = 1,
  Charter = 2,
}

export enum CharterKindType {
  Charter = 1,
  OwnersCharter,
  AllInclusive,
}

export enum CharterCommonType {
  Charter = 1,
  OwnersCharter,
  Trip,
  AllInclusive
}

export enum CharterContractStatus {
  Signed = 1,
  inProcess,
  Canceled
}

export interface ConvertedEventTimeZoneDetails {
  dateStart: string | null;
  timeStart: string | null;
  dateEnd: string | null;
  timeEnd: string | null;
  locationPickUp: LocationAutocompleteFormI | null;
  locationDropOff: LocationAutocompleteFormI | null;
}

export class EventDateTimeLocation {
  readonly dateStart: string;
  readonly timeStart: string;
  readonly dateEnd: string;
  readonly timeEnd: string;
  readonly timeZoneNameStart: string | null;
  readonly timeZoneNameEnd: string | null;

  constructor(
    {dateEnd, timeEnd, locationPickUp, locationDropOff, timeStart, dateStart}: EventDateTimeLocationForm,
  ) {
    this.dateStart = dateStart;
    this.timeStart = timeStart;
    this.dateEnd = dateEnd;
    this.timeEnd = timeEnd;
    this.timeZoneNameStart = locationPickUp?.addressInfo?.timeZone || null;
    this.timeZoneNameEnd = locationDropOff?.addressInfo?.timeZone || null;
  }
}

export interface EventDateTimeLocationForm {
  dateStart: string;
  timeStart: string;
  dateEnd: string;
  timeEnd: string;
  locationPickUp: ExtendedLocationItemDto;
  locationDropOff: ExtendedLocationItemDto;
}

export class EventTimeZoneDetailsDto {
  dateTimeStart: string | null;
  timeZoneNameStart: string | null;
  dateTimeEnd: string | null;
  timeZoneNameEnd: string | null;
  locationPickUp: LocationAutocompleteFormI | null;
  locationDropOff: LocationAutocompleteFormI | null;
}

export interface AddEditCharterConfig {
  title: string;
  mode: AddEditCharterMode;
  type: SystemCharterType;
  navItems: CharterNavItem[];
}

export interface AddEditCharterSectionComplete {
  name: string;
  attention: boolean;
}

export type CharterDateTimeLocationForm = EventDateTimeLocationForm;

export class CharterDateTimeLocation extends EventDateTimeLocation {
  constructor(
    form: CharterDateTimeLocationForm,
  ) {
    super(form);
  }
}

export interface CharterTripDetailsForm extends CharterDateTimeLocationForm {
  charterId: number;
  name: string | null;
  notes: string | null;
}

export class CharterTripDetailsPayload {

  readonly boatId: number;
  readonly charterId: number;
  readonly name: string | null;
  readonly dateTimeStart: string;
  readonly dateTimeEnd: string;
  readonly notes: string | null;
  readonly 'locationPickUp[timeZone]': string;
  readonly 'locationPickUp[name]': string;
  readonly 'locationPickUp[addressInfo]': string;
  readonly 'locationPickUp[searchType]': ExtendedLocationSearchType;
  readonly 'locationDropOff[timeZone]': string;
  readonly 'locationDropOff[name]': string;
  readonly 'locationDropOff[addressInfo]': string;
  readonly 'locationDropOff[searchType]': ExtendedLocationSearchType;

  constructor(
    boatId: number,
    form: CharterTripDetailsForm,
  ) {
    this.boatId = boatId;
    this.charterId = form.charterId;
    this.name = form.name;
    const {dateStart, timeStart, dateEnd, timeEnd, locationPickUp, locationDropOff} = form;

    const dateTimeStart =
      DateTimeUtcConverter.convertDateTimeToUtcString(dateStart, timeStart, locationPickUp.addressInfo.timeZone);
    if (dateTimeStart) {
      this.dateTimeStart = dateTimeStart;
    }
    const dateTimeEnd =
      DateTimeUtcConverter.convertDateTimeToUtcString(dateEnd, timeEnd, locationDropOff.addressInfo.timeZone);
    if (dateTimeEnd) {
      this.dateTimeEnd = dateTimeEnd;
    }
    this.notes = form.notes;
    this['locationPickUp[timeZone]'] = locationPickUp.addressInfo.timeZone;
    this['locationPickUp[name]'] = locationPickUp.address;
    this['locationPickUp[addressInfo]'] = JSON.stringify(locationPickUp.addressInfo);
    this['locationPickUp[searchType]'] = locationPickUp.searchType;
    this['locationDropOff[timeZone]'] = locationDropOff.addressInfo.timeZone;
    this['locationDropOff[name]'] = locationDropOff.address;
    this['locationDropOff[addressInfo]'] = JSON.stringify(locationDropOff.addressInfo);
    this['locationDropOff[searchType]'] = locationDropOff.searchType;
  }
}

export interface CharterTripDetailsDto extends EventTimeZoneDetailsDto {
  id: number;
  name: string | null;
  notes: string | null;
}

export interface ConvertedCharterTripDetails extends ConvertedEventTimeZoneDetails {
  charterId: number;
  name: string | null;
  notes: string | null;
}

export interface AddCharterPayload extends BoatPayload {
  charterType: SystemCharterType;
}

export interface AddCharterDto {
  id: number;
}

export interface BoatCharterParams {
  boatId: number;
  charterId: number;
}

export interface CharterDetailsForm extends CharterDateTimeLocationForm {
  boatId: number;
  charterId: number;
  charterKind: number;
  name: string;
  contractStatus: number;
  notes: string;
  chartererName: string;
}

export class CharterDetailsPayload extends CharterTripDetailsPayload {

  readonly charterKind: number;
  readonly contractStatus: number;
  readonly chartererName: string;

  constructor(
    boatId: number,
    form: CharterDetailsForm,
  ) {
    super(boatId, form);
    this.charterKind = form.charterKind;
    this.contractStatus = form.contractStatus;
    this.chartererName = form.chartererName;
  }
}

export interface CharterDetailsDto extends CharterTripDetailsDto {
  charterKind: number;
  contractStatus: number;
  chartererName: string;
}

export interface ConvertedCharterDetails extends ConvertedCharterTripDetails {
  contractStatus: number;
  charterKind: number;
  chartererName: string;
}

export class CharterDetailsDirectories {
  constructor(
    public readonly charterKinds: CharterKindDto[],
    public readonly charterContractStatuses: CharterContractStatusDto[],
  ) {
  }
}

export enum ContractFileType {
  Common = 1,
  MYBA = 2,
}

export enum CharterAttachmentDocumentType {
  ContractFile = 1,
  PreferenceList,
  CustomFile,
  BoardingPass,
}

export interface CharterAttachmentBasePayload {
  [key: string]: any;

  action: AddEditCharterMode;
}

export interface CharterAttachmentPayload extends CharterAttachmentBasePayload {
  charterId: number;
  notes: string;
  removeContractFiles: number[] | null;
  removePreferenceListFiles: number[] | null;
  removeCustomDocuments: number[] | null;
}

export interface TripAttachmentPayload extends CharterAttachmentBasePayload {
  tripId: number;
  removeCustomDocuments: number[] | null;
}

export interface CharterTripAttachmentFormI {
  customFiles: AttachmentCustomFileForm[];
  removeCustomDocuments: number[] | null;
}

export interface CharterAttachmentFormI extends CharterTripAttachmentFormI {
  contractType: ContractFileType;
  contractFiles: UploadedFile[] | null;
  removeContractFiles: number[] | null;
  preferenceListsFiles: UploadedFile[] | null;
  removePreferenceListFiles: number[] | null;
  notes: string;
  charterId: number;
}

export interface TripAttachmentFormI extends CharterTripAttachmentFormI {
  tripId: number;
}

export interface AttachmentCustomFileForm {
  id: number | null;
  customFiles: UploadedFile[] | null;
  customFileDocumentName: string;
  customFileNotes: string;
  removeFiles: number[] | null;
}

export class CharterCustomAttachmentFileFormDto implements AttachmentCustomFileForm {
  constructor(
    public readonly id: number | null,
    public readonly customFiles: UploadedFile[] | null,
    public readonly customFileDocumentName: string,
    public readonly customFileNotes: string,
    public readonly removeFiles: number[] | null = null,
  ) {
  }
}

export interface CharterCustomAttachmentDto {
  charterAttachmentContractDocumentId: number;
  documentName: string;
  notes: string;
  files: CharterCustomAttachmentFileDto[];
}

export interface CharterCustomAttachmentFileDto {
  charterAttachmentCustomFileId: number;
  filename: string;
  originalName: string;
  url: string;
}

export interface CharterTripCustomAttachmentDto {
  tripAttachmentContractDocumentId: number;
  documentName: string;
  notes: string;
  files: CharterTripCustomAttachmentFileDto[];
}

export interface CharterTripCustomAttachmentFileDto {
  tripAttachmentCustomFileId: number;
  filename: string;
  originalName: string;
  url: string;
}

export interface ContractAttachmentFile {
  charterAttachmentContractFileId: number;
  filename: string;
  originalName: string;
  url: string;
}

export interface PreferenceAttachmentListFile {
  charterAttachmentPreferenceListFileId: number;
  filename: string;
  originalName: string;
  url: string;
}

export interface CharterAttachment {
  charterAttachmentId: number;
  charterId: number;
  contract: CharterAttachmentContract;
  preferenceListFiles: PreferenceAttachmentListFile[];
  notes: string;
  customFiles: CharterCustomAttachmentDto[];
}

export interface CharterAttachmentContract {
  type: ContractFileType;
  files: ContractAttachmentFile[];
}

export interface TripAttachmentFile {
  tripAttachmentId: number;
  tripId: number;
  customFiles: CharterTripCustomAttachmentDto[];
}

export class CharterAttachmentsFormDto {
  readonly contractFiles: UploadedFile[] | null = null;
  readonly contractType: boolean;
  readonly notes: string;
  readonly preferenceListsFiles: UploadedFile[] | null = null;
  readonly charterId: number;
  readonly customFiles: CharterCustomAttachmentFileFormDto[];

  constructor(
    id: number,
    attachment: CharterAttachment,
  ) {
    const contract = attachment.contract;
    const preferenceListFiles = attachment.preferenceListFiles;
    if (contract) {
      this.contractType = contract.type === ContractFileType.MYBA;
      const files = contract.files ? contract.files : [];
      this.contractFiles = this.mapContractFiles(files);
    }
    if (preferenceListFiles?.length) {
      this.preferenceListsFiles = this.mapPreferenceFiles(preferenceListFiles);
    }
    this.notes = attachment.notes;
    if (attachment.customFiles) {
      this.customFiles = this.mapCustomFiles(attachment.customFiles);
    }

    this.charterId = id;
  }

  private mapContractFiles(files: ContractAttachmentFile[]): UploadedFile[] {
    return files.map(f => {
      const extension = fileExtension(f.filename);
      return new UploadedFile(
        f?.charterAttachmentContractFileId,
        null,
        f?.filename,
        null,
        f?.url,
        extension,
        mediaTypeByExtension(extension) as UploadedFileType,
        f?.originalName,
      );
    });
  }

  private mapPreferenceFiles(files: PreferenceAttachmentListFile[]): UploadedFile[] {
    return files.map(f => {
      const extension = fileExtension(f.filename);
      return new UploadedFile(
        f.charterAttachmentPreferenceListFileId,
        null,
        f?.filename,
        null,
        f?.url,
        extension,
        mediaTypeByExtension(extension) as UploadedFileType,
        f?.originalName);
    });
  }

  private mapCustomFiles(files: CharterCustomAttachmentDto[]): CharterCustomAttachmentFileFormDto[] {
    return files.map(file => {
      const uploadedFiles = file.files.map(f => {
        const extension = fileExtension(f.filename);
        return new UploadedFile(
          f.charterAttachmentCustomFileId,
          null,
          f.filename,
          null,
          f.url,
          extension,
          mediaTypeByExtension(extension) as UploadedFileType,
          f.originalName,
        );
      });
      return new CharterCustomAttachmentFileFormDto(
        file.charterAttachmentContractDocumentId,
        uploadedFiles,
        file.documentName,
        file.notes);
    });
  }
}

export class TripAttachmentsFormDto {
  readonly tripId: number;
  readonly customFiles: CharterCustomAttachmentFileFormDto[];

  constructor(id: number, attachment: TripAttachmentFile) {
    this.tripId = id;
    if (attachment.customFiles) {
      this.customFiles = this.mapCustomFiles(attachment.customFiles);
    }
  }

  private mapCustomFiles(files: CharterTripCustomAttachmentDto[]): CharterCustomAttachmentFileFormDto[] {
    return files.map(file => {
      const uploadedFiles = file.files.map(f => {
        const extension = fileExtension(f.filename);
        return new UploadedFile(
          f.tripAttachmentCustomFileId,
          null,
          f.filename,
          null,
          f.url,
          extension,
          mediaTypeByExtension(extension) as UploadedFileType,
          f.originalName,
        );
      });
      return new CharterCustomAttachmentFileFormDto(
        file.tripAttachmentContractDocumentId,
        uploadedFiles,
        file.documentName,
        file.notes);
    });
  }
}

export interface CharterUseFulInfo {
  apaCurrency: CurrencyDto[] | null;
  charterCurrency: CurrencyDto[] | null;
}

export interface CharterAdditionalPaymentForm {
  id: number;
  name: string;
  status: CharterPaymentStatus;
  value?: string | null;
}

export interface CharterOtherPaymentForm {
  name: string;
  status: CharterPaymentStatus;
  value?: string;
}

export interface CharterPercentageControlForm {
  percentValue: string;
  enableFixed: boolean;
  fixedValue: string;
}

export interface CharterPaymentDetailsForm {
  currencyId: number;
  grossCharterIncomeNotRelevant: boolean;
  grossCharterIncome: string;
  deliveryFeeType: CharterPaymentStatus;
  deliveryFee?: string;
  vatMode: CharterPaymentVatMode;
  vatIncluded: boolean;
  vat: CharterPercentageControlForm | null;
  vatNote: string;
  brokersFeeNotRelevant: boolean;
  brokersFee: CharterPercentageControlForm | null;
  securityDepositNotRelevant: boolean;
  securityDeposit: string;
  apaAmountNotRelevant: boolean;
  apaAmount: string;
  apaCurrencyId: number;
  additionalPaymentsEnabled: boolean;
  additionalPayments: CharterAdditionalPaymentForm[];
  otherPayments: CharterOtherPaymentForm[];
  brokersFeeType: BrokerFeeType;
}

export class CharterPaymentDirectories {
  constructor(
    public readonly currencies: CurrencyDto[],
    public readonly payments: CharterAdditionalPaymentMethodDto[],
    public readonly statuses: LabeledItem<CharterPaymentStatus>[],
    public readonly vatModes: LabeledItem<CharterPaymentVatMode>[],
  ) {
  }
}

export interface CharterPaymentDetailsDto {
  charterId: number;
  charterAttachmentId: number;
  currencyId: number;
  grossCharterIncomeNotRelevant: boolean;
  grossCharterIncome: number | null;
  deliveryFeeType: CharterPaymentStatus;
  deliveryFee: number | null;
  vatMode: CharterPaymentVatMode | null;
  vatIncluded: boolean;
  vatPercent: number | null;
  vatFixed: boolean;
  vatValue: number | null;
  vatNote: string;
  brokersFeeNotRelevant: boolean;
  brokersFeeFixed: boolean;
  brokersFeePercent: number;
  brokersFeeValue: number;
  securityDepositNotRelevant: boolean;
  securityDeposit: number | null;
  apaAmountNotRelevant: boolean;
  apaAmount: number | null;
  apaCurrencyId: number | null;
  additionalPayments: CharterAdditionalPaymentDto[];
  otherPayments: CharterOtherPaymentDto[];
  dateCreate: string;
  dateUpdate: string;
  brokersFeeType: BrokerFeeType;
}

export enum CharterPaymentStatus {
  NotRelevant,
  Included,
  FixPrice
}

export enum CharterPaymentVatMode {
  GrossIncomeAndDelivery = 1,
  GrossIncome,
  All
}

export enum CharterPaymentRelevantStatus {
  Relevant,
  NotRelevant,
}

export enum CharterAdditionalDetailsStatus {
  Relevant,
  NotRelevant,
}

export enum FuelUnits {
  Liter = 1,
  Galon = 2,
}

export interface CharterAdditionalPaymentDto {
  charterPaymentId: number;
  id: number;
  additionalPaymentId: number;
  additionalPaymentStatus: CharterPaymentStatus;
  additionalPaymentValue: number | null;
  dateCreate: string;
  dateUpdate: string;
}

export interface CharterOtherPaymentDto {
  charterPaymentId: number;
  id: number;
  otherPaymentName: string;
  otherPaymentStatus: CharterPaymentStatus;
  otherPaymentValue: number | null;
  dateCreate: string;
  dateUpdate: string;
}

export class CharterPaymentDetailsPayload {
  [key: string]: any;

  readonly charterId: number;
  readonly currencyId: number;
  readonly grossCharterIncomeNotRelevant: CharterPaymentRelevantStatus;
  readonly grossCharterIncome: string | null = null;
  readonly deliveryFeeType: CharterPaymentStatus;
  readonly deliveryFee: string | null = null;
  readonly vatMode: CharterPaymentVatMode;
  readonly vatIncluded: CharterPaymentRelevantStatus;
  readonly vatPercent: string | null = null;
  readonly vatFixed: CharterPaymentRelevantStatus | null = null;
  readonly vatValue: string | null = null;
  readonly vatNote: string;
  readonly brokersFeeNotRelevant: CharterPaymentRelevantStatus;
  readonly brokersFeePercent: string | null = null;
  readonly brokersFeeFixed: CharterPaymentRelevantStatus | null = null;
  readonly brokersFeeValue: string | null = null;
  readonly securityDepositNotRelevant: CharterPaymentRelevantStatus;
  readonly securityDeposit: string | null = null;
  readonly apaAmountNotRelevant: CharterPaymentRelevantStatus;
  readonly apaAmount: string | null = null;
  readonly apaCurrencyId: number | null = null;
  readonly brokersFeeType: BrokerFeeType | null = null;

  constructor(form: CharterPaymentDetailsForm, charterId: number) {
    this.charterId = charterId;
    const {
      currencyId,
      grossCharterIncomeNotRelevant,
      grossCharterIncome,
      deliveryFeeType,
      deliveryFee,
      vatMode,
      vatIncluded,
      vatNote,
      vat,
      brokersFeeNotRelevant,
      brokersFee,
      securityDepositNotRelevant,
      securityDeposit,
      apaAmountNotRelevant,
      apaCurrencyId,
      apaAmount,
      brokersFeeType,
    } = form;
    this.currencyId = currencyId;
    this.grossCharterIncomeNotRelevant =
      CharterPaymentDetailsPayload.convertBooleanToRelevantStatus(grossCharterIncomeNotRelevant);
    if (grossCharterIncome) {
      this.grossCharterIncome = grossCharterIncome;
    }
    this.deliveryFeeType = deliveryFeeType;
    if (deliveryFee) {
      this.deliveryFee = deliveryFee;
    }
    this.vatMode = vatMode;
    this.vatIncluded = CharterPaymentDetailsPayload.convertBooleanToRelevantStatus(vatIncluded);
    if (vat && !grossCharterIncomeNotRelevant) {
      this.vatPercent = vat.percentValue;
      this.vatFixed = CharterPaymentDetailsPayload.convertBooleanToRelevantStatus(vat.enableFixed);
      this.vatValue = vat.fixedValue;
    }
    this.vatNote = vatNote;
    this.brokersFeeNotRelevant = CharterPaymentDetailsPayload.convertBooleanToRelevantStatus(brokersFeeNotRelevant);
    if (brokersFee && !grossCharterIncomeNotRelevant) {
      this.brokersFeePercent = brokersFee.percentValue;
      this.brokersFeeFixed = CharterPaymentDetailsPayload.convertBooleanToRelevantStatus(brokersFee.enableFixed);
      this.brokersFeeValue = brokersFee.fixedValue;
    }
    this.securityDepositNotRelevant =
      CharterPaymentDetailsPayload.convertBooleanToRelevantStatus(securityDepositNotRelevant);
    this.securityDeposit = securityDeposit;
    this.apaAmountNotRelevant = CharterPaymentDetailsPayload.convertBooleanToRelevantStatus(apaAmountNotRelevant);
    this.apaCurrencyId = apaCurrencyId;
    this.apaAmount = apaAmount;
    this.brokersFeeType = brokersFeeType;

    if(form.additionalPaymentsEnabled) {
      this.transformArrayToProperties(form.additionalPayments, 'additionalPayment');
      this.transformArrayToProperties(form.otherPayments, 'otherPayment');
    }
  }

  private static convertBooleanToRelevantStatus(value: boolean | null): CharterPaymentRelevantStatus {
    return value ? CharterPaymentRelevantStatus.NotRelevant : CharterPaymentRelevantStatus.Relevant;
  }

  private transformArrayToProperties(items: object[], parentKey: string): void {
    if (!items) {
      return;
    }
    items.forEach((item, index) => {
      const params = buildURLParams(item, false, parentKey, index);
      Object.keys(params).forEach(key => {
        this[key] = params[key];
      });
    });
  }
}

export interface CharterPaymentDetailsQuery extends Params {
  apa?: ConditionalStatus;
  additional?: ConditionalStatus;
}

export interface CharterHeaderAcceptedAction {
  canAddApa: boolean;
  canEnableApa: boolean;
  canAddCharterExpense: boolean;
}

export interface CharterAdditionalDetailsDto extends CharterAdditionalDetailsForm {
  charterId: number;
  form: CharterAdditionalDetailsFormDto;
}

export interface CharterAdditionalDetailsFormDto {
  unit: number;
  fuelStartCapacity: number;
  fuelEndCapacity: number;
  fuelPurchased: number;
  mileage: number;
  generators: FuelGenerators[];
}

export interface CharterAdditionalDetailsForm {
  unit: number;
  fuelStartCapacity: number;
  fuelEndCapacity: number;
  fuelPurchased: number;
  mileage: number;
  generators: FuelGeneratorsForm[];
}

export interface FuelGenerators {
  id?: number;
  generatorNumber: number;
  startHours: number;
  endHours: number;
  notRelevant: boolean | number;
}

export interface FuelGeneratorsForm {
  number: number;
  startHours: number;
  endHours: number;
  notRelevant: boolean | number;
}

export class CharterAdditionalDetailsPayload {
  readonly charterId: number;
  readonly unit: number;
  readonly fuelStartCapacity: number;
  readonly fuelEndCapacity: number;
  readonly fuelPurchased: number;
  readonly mileage: number;
  readonly generators: FuelGenerators[];

  constructor(form: CharterAdditionalDetailsForm, charterId: number) {
    this.charterId = charterId;
    const {
      unit,
      fuelStartCapacity,
      fuelEndCapacity,
      fuelPurchased,
      mileage,
      generators,
    } = form;
    this.unit = unit;
    this.fuelStartCapacity = fuelStartCapacity;
    this.fuelEndCapacity = fuelEndCapacity;
    this.fuelPurchased = fuelPurchased;
    this.mileage = mileage;
    const generatorsValue = generators.map(generator => {
      generator.notRelevant =
        CharterAdditionalDetailsPayload.convertBooleanToRelevantStatus(generator.notRelevant as boolean);
      return generator;
    });
    this.transformArrayToProperties(generatorsValue, 'generators');
  }

  private static convertBooleanToRelevantStatus(value: boolean | null): CharterAdditionalDetailsStatus {
    return value ? CharterAdditionalDetailsStatus.NotRelevant : CharterAdditionalDetailsStatus.Relevant;
  }

  private transformArrayToProperties(items: object[], parentKey: string): void {
    if (!items) {
      return;
    }
    items.forEach((item, index) => {
      const params = buildURLParams(item, false, parentKey, index);
      Object.keys(params).forEach(key => {
        this[key] = params[key];
      });
    });
  }
}

export interface AdditionalDetailsInfo {
  fuel: FuelInfo;
  mileage: number | null;
  generators: GeneratorInfo[];
  unit: number;
}

export interface GeneratorInfo {
  endHours: number | null;
  startHours: number | null;
  total: number | null;
}

export interface FuelInfo {
  startCapacity: number | null;
  purchased: number | null;
  endCapacity: number | null;
  consumption: number | null;
}

export type AttachmentCustomFileMode = 'create' | 'edit';

export type AttachmentCustomFileAction = 'save' | 'delete' | 'cancel';

export interface AttachmentCustomFileCloseData {
  form: AttachmentCustomFileForm | null;
  action: AttachmentCustomFileAction;
}

export class CharterTransactionDataDto {
  constructor(
    public readonly items: TableCategoryRowItem[],
    public readonly total?: number,
  ) {
  }
}

export interface BoatLocationHistoryParams {
  readonly boat: number;
  readonly charter: number;
  readonly page?: number;
}

export interface BoatLocationHistoryDto {
  readonly date: string;
  readonly location: string;
  readonly speed: number;
  readonly time: string;
}

export interface BoatPositionHistoryPath {
  readonly history: GeoJSON.FeatureCollection;
}

export interface BoatPositionHistoryPathData extends BoatPositionHistoryPath {
  readonly boatImage: string;
}

export interface CharterPayloadDto extends BoatPayloadDto {
  readonly charter: number;
}
